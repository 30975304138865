/* eslint no-undef: 0 */
function runMain(config) {

	// Listening for the authenticated event
     	$('[data-hover="dropdown"]').dropdownHover();

	/* ======= jQuery Placeholder ======= */
	/* Ref: https://github.com/mathiasbynens/jquery-placeholder */

	$('input, textarea').placeholder();

	/* ======= FAQ accordion ======= */
	function toggleIcon(events) {
		$(events.target)
			.prev('.panel-heading')
			.find('.panel-title a')
			.toggleClass('active');
		$(events.target)
			.prev('.panel-heading')
			.find('.panel-title i')
			.toggleClass('glyphicon-chevron-up glyphicon-chevron-down');
	}
	$('.panel').on('hidden.bs.collapse', toggleIcon);
	$('.panel').on('shown.bs.collapse', toggleIcon);

	const $body = $('body');

	$(document).on({
		ajaxStart() {
			$body.addClass('loading');
		},
		ajaxStop() {
			$body.removeClass('loading');
		},
	});

	/* ======= Testimonial Bootstrap Carousel ======= */
	/* Ref: http://getbootstrap.com/javascript/#carousel */
	$('#testimonials-carousel, #header-carousel').carousel({
		interval: 8000,
	});

	$('.api .whats-new').on('click', () => {
		const isVisible = $('.api .whats-new-dropdown').is(':visible');
		if (!isVisible) {
			$('.api .whats-new-dropdown').show();
			$('.api .whats-new .arrow').removeClass('glyphicon-chevron-down')
				.addClass('glyphicon-chevron-up');
		} else {
			$('.api .whats-new-dropdown').hide();
			$('.api .whats-new .arrow').removeClass('glyphicon-chevron-up')
				.addClass('glyphicon-chevron-down');
		}
	});

	$('.method-title').each((index, element) => {
		const details = $(element).next('.method-details');
		const isVisible = $(details).is(':visible');
		if (!isVisible) {
			$(details).show();
		}
	});
}

$(document).ready(() => {
	$.ajax({
		type: 'GET',
		url: '/assets/config/defaultConfig.json',
		dataType: 'json',
		success: (data) => {
			runMain(data);
		},
		error: (xhr, status, err) => {
			return console.error(err);
		},
	});

	$('.header-image .intro-image-col img').show();

	$('.additional-info table').addClass('table table-bordered');

});
